














































































































import { Vue, Component, Prop, Emit, InjectReactive } from 'vue-property-decorator';
import CrmChatCallerButton from '@/components/crm/chat-caller-button.vue';
import ContactModel from '@/models/crm/contact.model';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';

@Component({
  components: {
    CrmChatCallerButton,
  },
})
export default class CardContact extends Vue {
  @InjectReactive('conversationUserPermission') readonly conversationUserPermission!: ConversationUserPermissionsModel;

  @Prop()
  readonly contato!: ContactModel;

  @Prop()
  isConvertedProspect!: boolean;

  @Prop()
  disabledPhone!: boolean;

  @Prop()
  disabledWhatsApp!: boolean;

  @Emit('edit-contact')
  onEditContact(): ContactModel {
    return this.contato;
  }

  @Emit('delete-contact')
  onDeleteContact(): ContactModel {
    return this.contato;
  }

  @Emit('send-email')
  onSendEmail(): string {
    return this.contato.email;
  }
}
